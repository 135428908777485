import React, { FC } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { NotFound } from 'views/components/NotFound'
import { ContactPage } from 'views/screens/ContactPage'
import Home from 'views/screens/Home'
import { Metrics } from 'views/screens/Metrics'
import { ParticipatingStates } from 'views/screens/ParticipatingStates'
// import Login from 'views/screens/Login'
// import Profile from 'views/screens/Profile'
import { StartProgram } from 'views/screens/startProgram'

const MainRouter: FC = () => (
  <Switch>
    <Route path="(/|/national)" exact>
      <Home />
    </Route>
    <Route path="(/start-a-program|/national/start-a-program)">
      <StartProgram />
    </Route>
    <Route path="(/contact|/national/contact)">
      <ContactPage />
    </Route>
    <Route path="(/participating-states|/national/participating-states)">
      <ParticipatingStates />
    </Route>
    <Route path="(/metrics|/national/metrics)">
      <Metrics />
    </Route>
    <Route path="*">
      <NotFound />
    </Route>
  </Switch>
)

export default MainRouter
