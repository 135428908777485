import { ServiceResponse } from '@types'
import axios from 'axios'

export const getAll = async (): Promise<ServiceResponse> => {
  const query = `
  query {
    header {
      login {
        label
        path
      }
      logoResponsive {
        url
      }
      logo {
        url
      }
      options {
        external
        label
        path
      }
    } 
    home {
      intro {
        card { 
          title
          description
          author
          link {
            label
            path
            external                
          }
        }
        background {
          url
        }
      }
      reasons {
        title
        quote
        author
        items {
          icon {url}
          description
        }
        link {
          label
          path
          external
        }
      }
      description {
        background {
          url
        }
        title
        history
        link {
          label
          path
          external
        }            
      }
      metrics {
        title
        description
        link {
          label
          path
          external
        }
        background {
          url
        }
      }
      track {
        card2 {
          title
          description
          link {
            label
            path
            external
          }
        }
        background {
          url
        }
      }
      contact {
        title
        formName
        formEmail
        formMessage
        formOrganization
        textField
        link {
          label
          path
          external
        }       
      }
    }
      footer {
        copyright
        social {
          icon {
            url
          }
            url
        }
        column {
          title
          link {
            label
            path
            external
             }
        }
          column2 {
            title
            link {
              label
              path
              external
          }
        }
      }
      learnMore {
        top {
          title
          info
          close
          button {
            label
          }          
          background {
            url
          }
        }
        shades {
          title
          subTitle
          firstColumn {
            title
            description
          }
          secondColumn {
            title
            description
          }
        }
        challenge {
          title
          info
          background {
            url
          }
        }
        cardSection {
          title
          smallCard {
            title
            info
            photo {
              url
            }
          }
        } 
        details {
          smallColumns {
            title
            info
          }
        }
        saved {
          title
          infoCard {
            vector {
              url
            }
            icon {
              url
            }
            iconRight {
              url
            }
            info
            conclution
          }
        }
        multi {
          title
          close
          paragraph
        }
        video {
          info
          embed
        }
        steps {
          title
          Steps_cards {
            stepNumber
            title
            description
            hiddenText            
            icon {
              url
            }
          }
        }  
      }
      nationalState {
        title
        subtitle
        introMessage
        introMessage2
        stateInfo {
          icon {
            url
          }
          title
          info
          code
          button {
            label
            path
          }
        }
      }
      nationalMetric {
        title
        tab1
        tab2
        tab3
        tab4
        subTabs {
          tab
          icon {
            url
          }
        }
        subTabRender {
          title
          iconLeft {url}
          iconRight {url}
          textLeft
          textRight
        }
        table {
          header {
            title
          }
        }
        Tab3Content {
          title
          subtitle
          rightColumn         
          button { 
            path
            label        
          }
        }
        Tab4Content {
          SubTab1Text
          SubTab1ButtonLabel
        }
    }
  }
  `

  try {
    const {
      data: { data },
    } = await axios.post(`${process.env.REACT_APP_MAIN_API}/graphql`, { query })
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}
